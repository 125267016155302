import { useNavigate } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";
import ScanResults from "../../ScanResults";
import Step1 from "./Step1";
import "../../../../Styles/apexmediasol.css";

const ApexMediasolTemplate = (props: any) => {
  const navigate = useNavigate();
  const {
    vendor,
    activeTab,
    formFields,
    handleStep2,
    createAndDownloadPdf,
    reportId,
  } = props;

  return (
    <>
      <div className="apexmediasol">
        <Container fluid className="bg-white">
          <Row>
            <Col className="apexmediasol-header">
              <img
                src="/images/apex-media.png"
                alt="Apex Mediasol Logo"
                className="apexmediasol-logo img-fluid"
                onClick={(e) => navigate("/home")}
              />
            </Col>
          </Row>
        </Container>
        <div className="">
          {(activeTab === 1 || activeTab === 2) && (
            <Step1
              vendor={vendor}
              formFields={formFields}
              onSubmit={handleStep2}
            />
          )}

          {activeTab === 3 && (
            <ScanResults
              vendor={vendor}
              createAndDownloadPdf={createAndDownloadPdf}
              reportId={reportId}
            />
          )}

          <footer className="apexmediasol-footer">
            <Container fluid>
              <Row>
                <Col className="apexmediasol-header">
                  <img
                    src="/images/apex-media.png"
                    alt="Apex Mediasol Logo"
                    className="apexmediasol-logo img-fluid"
                    onClick={(e) => navigate("/home")}
                  />
                </Col>
              </Row>
            </Container>
          </footer>
        </div>
      </div>
    </>
  );
};

export default ApexMediasolTemplate;
