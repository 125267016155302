import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
  hideLoadingToast,
  showErrorToast,
  showLoadingToast,
} from "../../Redux/slices/toastSlice";
import { scan } from "../../Services/public-service";
import { haveValue } from "../../Utils/helpers";
import ContentLoader from "../Loader/ContentLoader";
import SmallBusinessInfoTemplate from "./Templates/SmallBusinessInfo/SmallBusinessInfoTemplate";
import SynupTemplate from "./Templates/Synup/SynupTemplate";
import LeadCreaseTemplate from "./Templates/LeadCrease/LeadCreaseTemplate";
import ApexMediasolTemplate from "./Templates/Apexmediasol/ApexmeDiasolTemplate";

const Scanner = () => {
  const [template, setTemplate] = useState("");

  const configurations = useSelector((state: any) => state.configurations);
  const vendor = configurations?.vendor;

  const [activeTab, setActiveTab] = useState(1);
  const [formFields, setFormFields]: any = useState({});
  const [submitScan, setSubmitScan]: any = useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { reportId } = useParams();

  const handleStep1 = (values) => {
    setFormFields({
      ...formFields,
      ...values,
    });
    setActiveTab(2);
  };

  const handleStep2 = (values) => {
    setFormFields({
      ...formFields,
      ...values,
    });
    setSubmitScan(true);
  };

  useEffect(() => {
    if (submitScan && haveValue(formFields?.businessName)) {
      initialScan();
    }
  }, [formFields, submitScan]);

  const initialScan = () => {
    setSubmitScan(false);
    dispatch(showLoadingToast(null));
    scan(formFields)
      .then((res: any) => {
        navigate("/home/" + res?.data?._id);
      })
      .catch((err: any) => {
        dispatch(showErrorToast(err?.message));
      })
      .finally(() => {
        dispatch(hideLoadingToast(null));
      });
  };

  useEffect(() => {
    if (haveValue(reportId)) {
      setActiveTab(3);
    } else {
      setActiveTab(1);
    }
  }, [reportId]);

  const createAndDownloadPdf = async (reportId) => {
    dispatch(showLoadingToast("Generating Pdf"));

    let clientWidth: any = document?.body?.clientWidth;
    let clientHeight: any = document?.body?.clientHeight;

    let htmlString: any = document.documentElement.innerHTML;
    let iframe: any = document.createElement("iframe");
    iframe.style.visibility = "hidden";
    iframe.style.width = `${clientWidth}px`;
    iframe.style.height = `${clientHeight}px`;
    // iframe.style.height = "100px";
    document.body.appendChild(iframe);
    let iframedoc: any =
      iframe?.contentDocument || iframe?.contentWindow?.document;
    iframedoc.body.innerHTML = htmlString;

    let canvas = await html2canvas(iframedoc.body, {});

    // Convert the iframe into a PNG image using canvas.
    let imgData = canvas.toDataURL("image/png");

    // Create a PDF document and add the image as a page.
    const doc = new jsPDF({
      unit: "px",
      format: [clientWidth, clientHeight],
    });
    doc.addImage(imgData, "PNG", 0, 0, clientWidth, clientHeight);
    doc.save(`${reportId}.pdf`);
    //hide loader
    dispatch(hideLoadingToast(null));
    document.body.removeChild(iframe);
  };

  useEffect(() => {
    if (configurations?.loaded) {
      document.title = vendor?.name;
      if (haveValue(vendor?.template)) {
        setTemplate(vendor?.template);
      } else {
        setTemplate("synup");
      }
    }
  }, [vendor, configurations]);

  useEffect(() => {
    document.body.setAttribute("data-template", template);
  }, [template]);

  const renderComponent = () => {
    const props = {
      formFields,
      activeTab,
      vendor,
      handleStep1,
      handleStep2,
      createAndDownloadPdf,
      reportId,
    };

    switch (template) {
      case "SmallBusinessInfo":
        return <SmallBusinessInfoTemplate {...props} />;
      case "LeadCrease":
        return <LeadCreaseTemplate {...props} />;
      case "apexmediasol":
        return <ApexMediasolTemplate {...props} />;
      case "synup":
        return <SynupTemplate {...props} />;
    }
  };

  return (
    <>
      <div className="main_wrapper bg container-fluid">
        {!configurations?.loaded ? (
          <>
            <ContentLoader />
          </>
        ) : (
          <>
            {!haveValue(vendor?.name) ? (
              <>
                <h1>Not Found</h1>
              </>
            ) : (
              <>{renderComponent()}</>
            )}
          </>
        )}
      </div>
    </>
  );
};

export default Scanner;
