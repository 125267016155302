import { Container, Row } from "react-bootstrap";
import { useRef } from "react";
import Step1Form from "./Step1Form";

const Step1 = (props: any) => {
  const sectionRef = useRef<HTMLDivElement>(null);

  const scrollToSection = () => {
    sectionRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <>
      <div className="apexmediasol-banner apexmediasol-container">
        <div className="apexmediasol-banner-overlay">
          <div className="apexmediasol-banner-box px-0 container text-center">
            <h1>
              Voice Search Isn't the Future <br />
              <span>It's Happening Now</span>
            </h1>
            <h3>Is Your Business Ready?</h3>
          </div>
        </div>
      </div>
      <Container className="apexmediasol-voice-search-text">
        <Row>
          <div className="col-12 col-lg-12 text-center">
            <p
              className="fw-bold"
              style={{ color: "inherit", fontSize: "2.5rem" }}
            >
              Boost Your Visibility with Voice Search
            </p>
            <p
              style={{
                color: "inherit",
                fontWeight: "600",
                fontSize: "1.2rem",
              }}
              className="pt-2"
            >
              Get Discovered on Alexa, Siri, and More!
            </p>
          </div>
        </Row>
      </Container>
      <Container ref={sectionRef} className="apexmediasol-form">
        <Row>
          <div className="col-12">
            <div className="apexmediasol-form-main-wrapper">
              <h1 className="text-center">Enter Details to Scan Now !</h1>
              <Step1Form {...props} />
            </div>
          </div>
        </Row>
      </Container>
      <Container className="scan-now-section p-0">
        <div className="col-12">
          <div className="d-flex  two-column-set justify-content-between align-items-center ">
            <p className="scan-heading">
              <span>CLAIM YOUR FREE BUSINESS SCAN NOW</span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="41"
                height="29"
                viewBox="0 0 41 29"
                fill="none"
              >
                <path
                  d="M41 14.5L26.8877 0.499999L24.8978 2.474L35.595 13.1L-5.50764e-07 13.1L-6.73155e-07 15.9L35.595 15.9L24.8978 26.526L26.8877 28.5L41 14.5Z"
                  fill="#222222"
                />
              </svg>
            </p>
            <button
              type="button"
              className="apexmediasol-btn"
              onClick={scrollToSection}
            >
              SCAN NOW
            </button>
          </div>
        </div>
      </Container>
      <section className="why-apexmediasol">
        <Container>
          <Row>
            <div className="col-12">
              <div className="apexmediasol-business-section">
                <Row>
                  <div className="col-12 col-lg-12 text-center">
                    <h2>Why Apex Media Solutions Is a Game Changer for Your Business:</h2>
                  </div>
                </Row>
                <Row className="apexmediasol-business-section-two">
                  <div className="col-12 col-lg-3 apexmediasol-why-us-card">
                    <div className="apexmediasol-business-section-card">
                      <img src="/images/lead-crease-home-icon.png" alt="" />
                      <h5 className="card-heading">
                        Be Found Where It Matters Most
                      </h5>
                      <p>
                        More customers are using their voice to find local
                        businesses, Apex Media Solutions ensures you’re the business they
                        find.
                      </p>
                    </div>
                  </div>
                  <div className="col-12 col-lg-3 apexmediasol-why-us-card">
                    <div className="apexmediasol-business-section-card">
                      <img src="/images/lead-crease-home-icon.png" alt="" />
                      <h5 className="card-heading">
                        Be Found Where It Matters Most
                      </h5>
                      <p>
                        More customers are using their voice to find local
                        businesses, Apex Media Solutions ensures you’re the business they
                        find.
                      </p>
                    </div>
                  </div>
                  <div className="col-12 col-lg-3 apexmediasol-why-us-card">
                    <div className="apexmediasol-business-section-card">
                      <img src="/images/lead-crease-home-icon.png" alt="" />
                      <h5 className="card-heading">
                        Be Found Where It Matters Most
                      </h5>
                      <p>
                        More customers are using their voice to find local
                        businesses, Apex Media Solutions ensures you’re the business they
                        find.
                      </p>
                    </div>
                  </div>
                  <div className="col-12 col-lg-3 apexmediasol-why-us-card">
                    <div className="apexmediasol-business-section-card">
                      <img src="/images/lead-crease-home-icon.png" alt="" />
                      <h5 className="card-heading">
                        Be Found Where It Matters Most
                      </h5>
                      <p>
                        More customers are using their voice to find local
                        businesses, Apex Media Solutions ensures you’re the business they
                        find.
                      </p>
                    </div>
                  </div>
                </Row>
                <Container className="apexmediasol-scan-now-section p-0">
                  <div className="col-12">
                    <div className="d-flex two-column-set justify-content-between align-items-center">
                      <p className="apexmediasol-scan-heading">
                        <span>CLAIM YOUR FREE BUSINESS SCAN NOW</span>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="41"
                          height="29"
                          viewBox="0 0 41 29"
                          fill="none"
                        >
                          <path
                            d="M41 14.5L26.8877 0.499999L24.8978 2.474L35.595 13.1L-5.50764e-07 13.1L-6.73155e-07 15.9L35.595 15.9L24.8978 26.526L26.8877 28.5L41 14.5Z"
                            fill="#222222"
                          />
                        </svg>
                      </p>
                      <button
                        type="button"
                        className="apexmediasol-btn"
                        onClick={scrollToSection}
                      >
                        SCAN NOW
                      </button>
                    </div>
                  </div>
                </Container>
              </div>
            </div>
          </Row>
        </Container>
      </section>
      <Container className="apexmediasol-discover-section">
        <Row>
          <div className="col-sm-12">
            <h2 className="text-center">
              Discover How Visible Your Business Is to Customers Searching on
              Alexa, Siri, and Google—Run Your Free Scan Today!
            </h2>
            <h5 className="text-center mb-4">Here's what sets us apart:</h5>
            <div className="apexmediasol-discover-list-section">
              <div className="row">
                <div className="col-12 col-lg-6">
                  <div className="apexmediasol-discover-list">
                    <ul>
                      <li>
                        <strong>Optimized for Voice Search:</strong> Unlike
                        other companies that focus solely on traditional
                        directories, Apex Media Solutions specializes in getting your
                        business discovered on Alexa, Siri, and Google
                        Assistant—where more customers are searching every day.
                      </li>
                      <li>
                        <strong>
                          Comprehensive Local Presence Management:
                        </strong>{" "}
                        From voice platforms to Google My Business, we optimize
                        your visibility across all major channels, ensuring your
                        business shows up in the right places.
                      </li>
                      <li>
                        <strong>Actionable Insights and Easy Setup:</strong> Our
                        Free Business Scan gives you a clear snapshot of your
                        online presence and actionable steps to improve, so you
                        can start seeing results fast.
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-12 col-lg-6">
                  <div className="apexmediasol-discover-list">
                    <ul>
                      <li>
                        <strong>Scalable Solutions for Any Business:</strong>{" "}
                        Whether you’re just starting or looking to dominate your
                        local market, Apex Media Solutions offers flexible packages that
                        grow with you, from essential listings to advanced local
                        SEO strategies.
                      </li>
                      <li>
                        <strong>Dedicated Support and Expertise:</strong> With
                        Apex Media Solutions, you’re not navigating the digital landscape
                        alone. Our team is here to guide you every step of the
                        way, making the process simple and stress-free.
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Row>
      </Container>
      <Container className="row g-0 w-100 apexmediasol-container apexmediasol-footer-scan mt-5">
        <div className="col-sm-12">
          <p>
            Apex Media Solutions simplifies the process, so you can focus on running your
            business while we handle your visibility. It’s time to attract more
            local customers and grow with confidence.
          </p>
          <div className="text-center">
            <button
              type="button"
              className="apexmediasol-btn"
              onClick={scrollToSection}
            >
              SCAN NOW
            </button>
          </div>
        </div>
      </Container>
    </>
  );
};

export default Step1;
