import { Container, Row } from "react-bootstrap";
import Step1Form from "./Step1Form";
import { useRef } from "react";

const Step1 = (props: any) => {
  const sectionRef = useRef<HTMLDivElement>(null);

  const scrollToSection = () => {
    sectionRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <>
      <div className="lead-crease-banner lead-crease-container">
        <div className="lead-crease-banner-box px-0 container">
          <h1>
            Voice Search Isn't the Future <br />
            <span>It's Happening Now</span>
          </h1>
          <h3>Is Your Business Ready?</h3>
        </div>
      </div>
      <Container className="voice-search-text">
        <Row>
          <div className="col-12 col-lg-6">
            <p
              className="fw-bold"
              style={{ color: "inherit", fontSize: "2.5rem" }}
            >
              Boost Your Visibility with Voice Search
            </p>
            <p
              style={{
                color: "inherit",
                fontWeight: "600",
                fontSize: "1.2rem",
              }}
              className="pt-2"
            >
              Get Discovered on Alexa, Siri, and More!
            </p>
          </div>
          <div className="col-12 col-lg-6 ">
            <p className="lh-lg">
              Traditional search results are saturated and people know they
              can’t always trust them, so why keep dumping money into the same
              old “optimization” game? Book a free call with us and find out how
              we can help your customers find you on major voice platforms.
            </p>
          </div>
        </Row>
      </Container>
      <Container ref={sectionRef} className="lead-crease-form">
        <Row>
          <div className="col-12">
            <div className="form-main-wrapper">
              <h1 className="text-center">Enter Details to Scan Now!</h1>
              <Step1Form {...props} />
            </div>
          </div>
        </Row>
      </Container>
      <Container className="scan-now-section p-0">
        <div className="col-12">
          <div className="d-flex  two-column-set justify-content-between align-items-center ">
            <p className="scan-heading">
              <span>CLAIM YOUR FREE BUSINESS SCAN NOW</span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="41"
                height="29"
                viewBox="0 0 41 29"
                fill="none"
              >
                <path
                  d="M41 14.5L26.8877 0.499999L24.8978 2.474L35.595 13.1L-5.50764e-07 13.1L-6.73155e-07 15.9L35.595 15.9L24.8978 26.526L26.8877 28.5L41 14.5Z"
                  fill="#222222"
                />
              </svg>
            </p>
            <button
              type="button"
              className="lead-crease-btn"
              onClick={scrollToSection}
            >
              SCAN NOW
            </button>
          </div>
        </div>
      </Container>
      <Container className="lead-crease-introduction">
        <Row>
          <div className="col-12 col-lg-6">
            <div className="lead-crease-introduction-section-one">
              <h3>
                Leverage the Power of VOICE and AI Enabled Online Searches to
                get ahead of your competition
              </h3>
              <p>
                Are you struggling to get your business noticed in today’s
                crowded digital landscape? With more customers using voice
                search to find local businesses, showing up on platforms like
                Alexa, Siri, and Google Assistant isn’t just a luxury—it’s
                essential. But figuring out how to optimize your presence can
                feel overwhelming and out of reach
              </p>
              <p>
                That’s where the LeadCrease Free Business Scan comes in. This
                simple, powerful tool evaluates how discoverable your business
                is across major voice platforms and provides actionable insights
                to help you attract more customers.
              </p>
            </div>
          </div>
          <div className="col-12 col-lg-6">
            <div className="lead-crease-introduction-section-two">
              <h3>
                Book a free call with us today, and we’ll include a free
                business scan to show you:
              </h3>
              <div className="lead-crease-introduction-list">
                <ul className="d-flex flex-column gap-3">
                  <li>
                    How easily your business can be found on Alexa, Siri, and
                    other voice-search platforms.{" "}
                  </li>
                  <li>
                    What steps to take to boost your visibility and stay ahead
                    of the competition.
                  </li>
                  <li>
                    How we can connect you with more customers searching for
                    businesses just like yours.
                  </li>
                </ul>
              </div>
              <div className="custom-progress-bar"></div>
            </div>
          </div>
        </Row>
      </Container>
      <section className="left-image-section">
        <Container>
          <Row>
            <div className="col-12 col-lg-6">
              <div className="lead-crease-introduction-section-three">
                <img
                  className="left-full-image"
                  src="/images/lead-crease-computer.webp"
                  alt=""
                />
              </div>
            </div>
            <div className="col-12 col-lg-6">
              <div className="lead-crease-introduction-section-four">
                <p>
                  No guesswork, no complicated setup—just a clear path to
                  getting found by more customers and growing your business with
                  confidence
                </p>
                <p>
                  Take the first step today by getting on a call with us and get
                  your Free Business Scan.
                </p>
                <p>Claim Your Free business Scan now!</p>
                <button
                  type="button"
                  className="lead-crease-btn"
                  onClick={scrollToSection}
                >
                  SCAN NOW
                </button>
              </div>
            </div>
          </Row>
        </Container>
      </section>
      <section className="why-leadcrease">
        <Container>
          <Row>
            <div className="col-12">
              <div className="lead-crease-business-section">
                <Row>
                  <div className="col-12 col-lg-6">
                    <h2>Why LeadCrease Is a Game Changer for Your Business:</h2>
                  </div>
                  <div className="col-12 col-lg-6">
                    <p>
                      LeadCrease isn’t just another local listing service—it’s a
                      complete solution designed to help your business thrive in
                      the voice search era. With cutting-edge technology and
                      personalized support, LeadCrease ensures your business
                      stands out where it matters most.
                    </p>
                  </div>
                </Row>
                <Row className="lead-crease-business-section-two justify-content-center">
                  <div className="col-12 col-lg-4 why-us-card">
                    <div className="lead-crease-business-section-card">
                      <img src="/images/user.png" className="mb-3" alt="" />
                      {/* <h5 className="card-heading">
                        Be Found Where It Matters Most
                      </h5> */}
                      <p>
                        1 in 5 adults surveyed uses mobile voice search at least
                        once a month
                      </p>
                    </div>
                  </div>
                  <div className="col-12 col-lg-4 why-us-card">
                    <div className="lead-crease-business-section-card">
                      <img src="/images/shop.png" className="mb-3" alt="" />
                      {/* <h5 className="card-heading">
                        Be Found Where It Matters Most
                      </h5> */}
                      <p>
                        58% of consumers have used voice search to find local
                        businesses.
                      </p>
                    </div>
                  </div>
                  <div className="col-12 col-lg-4 why-us-card">
                    <div className="lead-crease-business-section-card">
                      <img src="/images/mic.png" className="mb-3" alt="" />
                      {/* <h5 className="card-heading">
                        Be Found Where It Matters Most
                      </h5> */}
                      <p>
                        2 in 5 users perform voice search at least once a day.
                      </p>
                    </div>
                  </div>
                  <div className="col-12 col-lg-4 why-us-card">
                    <div className="lead-crease-business-section-card">
                      <img src="/images/location.png" className="mb-3" alt="" />
                      {/* <h5 className="card-heading">
                        Be Found Where It Matters Most
                      </h5> */}
                      <p>
                        76% of smart home speaker users perform local searches
                        at least once a week.
                      </p>
                    </div>
                  </div>
                  <div className="col-12 col-lg-4 why-us-card">
                    <div className="lead-crease-business-section-card">
                      <img src="/images/cart.png" className="mb-3" alt="" />
                      {/* <h5 className="card-heading">
                        Be Found Where It Matters Most
                      </h5> */}
                      <p>
                        22% of smart home speaker owners have made a purchase
                        using their device.
                      </p>
                    </div>
                  </div>
                </Row>
                <Container className="scan-now-section p-0">
                  <div className="col-12">
                    <div className="d-flex two-column-set justify-content-between align-items-center">
                      <p className="scan-heading">
                        <span>CLAIM YOUR FREE BUSINESS SCAN NOW</span>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="41"
                          height="29"
                          viewBox="0 0 41 29"
                          fill="none"
                        >
                          <path
                            d="M41 14.5L26.8877 0.499999L24.8978 2.474L35.595 13.1L-5.50764e-07 13.1L-6.73155e-07 15.9L35.595 15.9L24.8978 26.526L26.8877 28.5L41 14.5Z"
                            fill="#222222"
                          />
                        </svg>
                      </p>
                      <button
                        type="button"
                        className="lead-crease-btn"
                        onClick={scrollToSection}
                      >
                        SCAN NOW
                      </button>
                    </div>
                  </div>
                </Container>
              </div>
            </div>
          </Row>
        </Container>
      </section>
      <Container className="discover-section">
        <Row>
          <div className="col-sm-12">
            <h2>
              Discover How Visible Your Business Is to Customers Searching on
              Alexa, Siri, and Google—Run Your Free Scan Today!
            </h2>
            <h5>Here’s what sets us apart:</h5>
            <div className="lead-crease-discover-list-section">
              <div className="row">
                <div className="col-12 col-lg-6">
                  <div className="lead-crease-discover-list">
                    <ul>
                      <li>
                        <strong>Optimized for Voice Search:</strong> Unlike
                        other companies that focus solely on traditional
                        directories, LeadCrease specializes in getting your
                        business discovered on Alexa, Siri, and Google
                        Assistant—where more customers are searching every day.
                      </li>
                      <li>
                        <strong>
                          Comprehensive Local Presence Management:
                        </strong>{" "}
                        From voice platforms to Google My Business, we optimize
                        your visibility across all major channels, ensuring your
                        business shows up in the right places.
                      </li>
                      <li>
                        <strong>Actionable Insights and Easy Setup:</strong> Our
                        Free Business Scan gives you a clear snapshot of your
                        online presence and actionable steps to improve, so you
                        can start seeing results fast.
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-12 col-lg-6">
                  <div className="lead-crease-discover-list">
                    <ul>
                      <li>
                        <strong>Scalable Solutions for Any Business:</strong>{" "}
                        Whether you’re just starting or looking to dominate your
                        local market, LeadCrease offers flexible packages that
                        grow with you, from essential listings to advanced local
                        SEO strategies.
                      </li>
                      <li>
                        <strong>Dedicated Support and Expertise:</strong> With
                        LeadCrease, you’re not navigating the digital landscape
                        alone. Our team is here to guide you every step of the
                        way, making the process simple and stress-free.
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>

            <div className="lead-crease-discover-deliver-section">
              <div className="row ">
                <div className="col-12 col-lg-6">
                  <div className="lead-crease-discover-deliver-section-one">
                    <h4>How LeadCrease Delivers Real Results</h4>
                    <p>
                      Our streamlined system ensures your business stands out in
                      today’s voice-driven world by optimizing your presence
                      where customers are searching most. Here’s how we do it:
                    </p>
                    <ul>
                      <li>
                        <strong>
                          Comprehensive Voice Search Optimization:
                        </strong>{" "}
                        We ensure your business is fully listed and discoverable
                        on Alexa, Siri, and Google Assistant, making it easy for
                        customers to find you.
                      </li>
                      <li>
                        <strong>Personalized Business Insights:</strong> Our
                        Free Business Scan evaluates your current visibility and
                        provides actionable recommendations to improve your
                        online presence.
                      </li>
                      <li>
                        <strong>Enhanced Local Listings:</strong> We optimize
                        your Google My Business profile and other key
                        directories to increase your chances of being found by
                        local customers.
                      </li>
                      <li>
                        <strong>Ongoing Support and Updates:</strong> From
                        review management to local SEO enhancements, we provide
                        continuous updates to keep your business ahead of the
                        competition.
                      </li>
                    </ul>
                    <div className="custom-progress-bar"></div>
                  </div>
                </div>
                <div className="col-12 col-lg-6">
                  <div className="lead-crease-discover-deliver-section-two">
                    <p className="lead-crease-discover-deliver-section-two-heading-one">
                      This isn’t just about being listed—it’s about being{" "}
                      <strong>found and chosen by your customers</strong>
                    </p>
                    <p className="lead-crease-discover-deliver-section-two-heading-two">
                      LeadCrease empowers your business to thrive in an
                      increasingly digital and voice-driven world.
                    </p>
                    <p className="lead-crease-discover-deliver-section-two-heading-three">
                      We understand that optimizing your business for voice
                      search can feel like navigating uncharted territory—but
                      that’s exactly why we’re here. At LeadCrease, we’re
                      committed to delivering measurable results.
                    </p>
                    <p className="lead-crease-discover-deliver-section-two-heading-three">
                      Your success is our priority, and we’re here to ensure
                      your business stands out in today’s voice-driven world.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Row>
      </Container>
      <Container className="row g-0 w-100 lead-crease-container lead-crease-footer-scan mt-5">
        <div className="col-sm-12">
          <p>
            LeadCrease simplifies the process, so you can focus on running your
            business while we handle your visibility. It’s time to attract more
            local customers and grow with confidence.
          </p>
          <div className="text-center">
            <button
              type="button"
              className="lead-crease-btn"
              onClick={scrollToSection}
            >
              SCAN NOW
            </button>
          </div>
        </div>
      </Container>
    </>
  );
};

export default Step1;
